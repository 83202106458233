import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../apis/ServerApis';
import { TextField } from '@material-ui/core';
import InputBase from '@mui/material/InputBase';
import Autocomplete from '@mui/material/Autocomplete';

import { getErrorText, infoButton } from '../../utility/helperFunctions';
import { checkEmailisValid, handleException, handleFailure, showAlert } from '../../utility/util';
import {
  functionDepartment,
  colleagueRole,
  levelDesignation,
  peerLevel,
  userDetail,
} from '../../utility/constatnt';
import { useEffect } from 'react';
import { SmallHeader } from '../../components/common/SmallHeader';
import { FormPopupSelect } from '../../components/common/FormPopup/FormPopupSelect';
import { RoutePaths } from '../../navigation/RoutePath';
import { getLocalStorageItem } from '../../utility/localStorage';
import Api from '../../apis/ServerApis';
import { MainHeader } from '../auth/components/MainHeader';
import { PageContent } from '../../components/common';
import styled from 'styled-components';

export const CssTextField = styled(TextField).attrs((props) => ({}))({
  '& label': {
    color: '#76839d',
  },
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#76839d',
  },
  '& .MuiInputBase-input': { color: 'white' },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#76839d',
    },
    '&:hover fieldset': {
      borderColor: '#76839d',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
});

const AddColleague = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const location = useLocation();
  const navigate = useNavigate();
  var companyData = location.state;
  const [functionDept, setFunctionDept] = useState(functionDepartment);
  const [inputValue, setInputValue] = useState('');
  const [colleagueName, setColleagueName] = useState('');
  const [colleagueEmail, setColleagueEmail] = useState('');
  const [colleagueList, setColleagueList] = useState([]);
  const [colleagueNameResponse, setColleagueNameResponse] = useState([]);
  const [functions, setFunction] = useState('');
  const [roleDept, setRoleDept] = useState([]);
  const [allColleagues, setAllColleagues] = useState([]);
  const [companyParam, setCompanyParam] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);

  // Get company data
  useEffect(() => {
    // if (JSON.parse(getLocalStorageItem(userDetail)) === null) {
    //   navigate(RoutePaths.auth);
    // }
    if (companyData) {
      console.log(companyData);
      setCompanyParam(companyData);
    }
  }, []);

  // If user direct click on submit so first save colleague data and then call api
  useEffect(() => {
    if (submitClicked) {
      saveColleagues();
      setSubmitClicked(false);
    }
  }, [allColleagues]);

  // call an api to get colleague name after user enter 3 character
  const getColleagueNames = (e, value) => {
    console.log('colleague Name : ', e);
    console.log('value : ', value);
    setColleagueName(value);
    if (value.length === 3) {
      const url = `get-users?name=${value}`;
      Api('GET', url)
        .then((response) => {
          if (response.success) {
            if (response.data.length !== 0) {
              console.log(response.data);
              filterColleagues(response.data, value);
              setColleagueNameResponse(response.data);
            }
          } else {
            handleFailure(response);
          }
        })
        .catch((error) => {
          handleException(error);
        });
    } else {
      if (colleagueNameResponse.length > 0) {
        filterColleagues(colleagueNameResponse, value);
      }
    }
  };

  // Filter Colleague
  const filterColleagues = (data, searchValue) => {
    const filterColleaguedata = data.filter((value) => {
      return value.name.toLowerCase().includes(searchValue.toLowerCase());
    });
    console.log('filterData : ', filterColleaguedata);
    setColleagueList(filterColleaguedata);
  };

  // Fill Department dropdown according to selected function
  const handleFunctionDept = (selectedDept) => {
    console.log(selectedDept);
    let arrRole1 = [];

    arrRole1 = colleagueRole.filter((data) => data.value.includes(selectedDept));
    const sortRole = [...arrRole1].sort((a,b) => a.label > b.label ? 1 : -1);

    setFunction(selectedDept);
    setRoleDept(sortRole);
  };

  // Handle Add Another Colleague
  const onSubmit = (data) => {
    if (colleagueName.length === 0 || colleagueEmail.length === 0) {
      showAlert('Error', 'colleague name and email is requird');
      return;
    }

    if (!checkEmailisValid(colleagueEmail)) {
      showAlert('Error', 'Email is invalid');
      return;
    }
    const colleagueParams = {
      name: colleagueName,
      email: colleagueEmail,
      function: functions,
      role: data.role,
      level: data.level,
      peer_level: data.peerlevel,
      tags: '',
    };

    setAllColleagues([...allColleagues, colleagueParams]);

    reset();
    setFunction('');
    setColleagueName('');
    setColleagueEmail('');
    console.log('params :', colleagueParams);
    console.log(allColleagues);

    showAlert(
      'Success',
      'Colleague added successfully. Please submit all colleague once you added',
    );
  };

  // Api call for Add Company and Colleague
  const saveColleagues = () => {
    // console.log(companyParam);
    console.log(allColleagues);

    if (functions !== '') {
      setSubmitClicked(true);
    }

    if (allColleagues.length === 0) {
      showAlert('Error', 'Please add colleague');
      return;
    }

    const companyColleagueParam = {
      company: companyParam.company,
      colleagues: allColleagues,
    };

    setTimeout(() => {
      if (functions === '') {
        const url = 'post-company-new';
        api('POST', url, companyColleagueParam)
          .then((response) => {
            if (response.success) {
              console.log(response);
              console.log('function : ', functions);
              navigate(RoutePaths.addCompany);
              showAlert(
                'Success',
                'All Colleagues submitted successfully. Now, Colleagues can edit or confirm their details.'
              );
            } else {
              handleFailure(response);
            }
          })
          .catch((error) => {
            handleException(error);
          });
      }
    }, 1000);
  };

  return (
    <div className="container">
      <MainHeader title="Add Colleague" showBackButton />

      <PageContent>
        <form onSubmit={handleSubmit(onSubmit)} className="text-center h-100">
          <div className="form-input-container">
            {infoButton("This is the colleague you wish to involve building up your compR Score. This peer will accept/validate or edit and update the below information you set about its responsibilities in the selected company.")}
            <div className="form-input-group">
              {/* <input
                className="form-input form-input-with-icon form-input-username"
                placeholder="colleague name"
                {...register('colleaguename', { required: true })}
              /> */}
              <Autocomplete
                // id="free-solo-demo"
                freeSolo
                name="companyname"
                options={colleagueList}
                value={inputValue}
                onChange={(event, newValue) => {
                  console.log('newValue : ', newValue);
                  setInputValue(newValue);
                  if (typeof newValue === 'object' && newValue) {
                    setColleagueEmail(newValue.email);
                  }
                }}
                inputValue={colleagueName}
                onInputChange={(event, newInputValue) => {
                  getColleagueNames(event, newInputValue);
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      <span>{option.name}</span>
                    </li>
                  );
                }}
                getOptionLabel={(option) => option.name || ''}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    value={colleagueName}
                    color="primary"
                    label="Colleague Name"
                    variant="outlined"
                  />
                )}
              />
              <div className="error-text">{getErrorText(errors, 'colleaguename')}</div>
            </div>

            {infoButton("This is the email of your colleague. He/She will received an email mentioning the below elements you associated to his/her job is the company previously selected. Your name will not be mentionned.")}
            <div className="form-input-group">
              <CssTextField
                className="form-input full-width"
                variant="outlined"
                label="Email"
                value={colleagueEmail}
                onChange={(e) => setColleagueEmail(e.target.value)}
              />
              <div className="error-text">{getErrorText(errors, 'email')}</div>
            </div>

            {infoButton("For the selected company, your above named colleague is or was working in what Function/Department?")}
            <div className="form-pop-up-input-group flex">
              <Controller
                control={control}
                name="function"
                render={({ field: { value, ref } }) => (
                  <FormPopupSelect
                    className="select-field"
                    placeholder="Function"
                    inputRef={ref}
                    value={functionDept.find((c) => c.value === value)}
                    onChange={(val) => handleFunctionDept(val.value)}
                    options={functionDept}
                  />
                )}
              />
              <div className="error-text">{getErrorText(errors, 'function')}</div>
            </div>

            {infoButton("Your colleague department/function responsibility perimeter (Sub function) for the above Function/Department was...")}
            <div className="form-pop-up-input-group flex">
              <Controller
                control={control}
                name="role"
                render={({ field: { onChange, value, ref } }) => (
                  <FormPopupSelect
                    className="select-field"
                    placeholder="Role"
                    inputRef={ref}
                    value={roleDept.find((c) => c.label === value) || ''}
                    onChange={(val) => onChange(val.label)}
                    options={roleDept}
                  />
                )}
              />
              <div className="error-text">{getErrorText(errors, 'role')}</div>
            </div>

            {infoButton("Your Colleague seniority level within the above Function/Department was...")}
            <div className="form-pop-up-input-group flex">
              <Controller
                control={control}
                name="level"
                rules={{ required: true }}
                render={({ field: { onChange, value, ref } }) => (
                  <FormPopupSelect
                    className="select-field"
                    placeholder="Level"
                    inputRef={ref}
                    value={levelDesignation.find((c) => c.value === value) || ''}
                    onChange={(val) => onChange(val.value)}
                    options={levelDesignation}
                  />
                )}
              />
              <div className="error-text">{getErrorText(errors, 'level')}</div>
            </div>

            {infoButton("e.g. your Colleague was your boss (your N+1) or you were his/her Manager (your N-1)?")}
            <div className="form-pop-up-input-group flex">
              <Controller
                control={control}
                name="peerlevel"
                rules={{ required: true }}
                render={({ field: { onChange, value, ref } }) => (
                  <FormPopupSelect
                    className="select-field"
                    placeholder="Peer Level"
                    inputRef={ref}
                    value={peerLevel.find((c) => c.value === value) || ''}
                    onChange={(val) => onChange(val.value)}
                    options={peerLevel}
                  />
                )}
              />
              <div className="error-text">{getErrorText(errors, 'peerlevel')}</div>
            </div>
          </div>

          <button
            className="submit-btn margin-right-oneem margin-left-oneem"
            style={{
              marginBottom: 20,
            }}
            type="submit">
            Add Another Colleague
          </button>

          <button className="submit-btn" onClick={saveColleagues}>
            Submit
          </button>
        </form>
      </PageContent>
    </div>
  );
};

export default AddColleague;
