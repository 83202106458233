import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import api from '../../apis/ServerApis';

import { RoutePaths } from '../../navigation/RoutePath';
import { getErrorText } from '../../utility/helperFunctions';
import {
  checkEmailisValid,
  checkPasswordisValid,
  handleException,
  handleFailure,
  showAlert
} from '../../utility/util';
import { setLocalStorageItem } from '../../utility/localStorage';
import { accessToken, userDetail } from '../../utility/constatnt';

const Signup = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const navigate = useNavigate();

  // Handle SignUp
  const onSubmit = (data) => {
    if (!checkEmailisValid(data.email)) {
      showAlert('Error', 'Email is invalid');
      return;
    }

    if (!checkPasswordisValid(data.password)) {
      showAlert(
        'Error',
        'Password must have one numeric digit, one uppercase and one lowercase letter'
      );
      return;
    }

    if (data.password !== data.confirmpassword) {
      showAlert('Error', 'Confirm password is not match with password');
      return;
    }

    const signupParams = {
      name: data.firstname + ' ' + data.lastname,
      email: data.email,
      password: data.password
    };

    // Api call for Signup
    const url = 'register';
    api('POST', url, signupParams)
      .then((response) => {
        console.log(response);
        if (response.success) {
          setLocalStorageItem(accessToken, response.data.token);
          setLocalStorageItem(userDetail, JSON.stringify(response.data.user));
          navigate(RoutePaths.addCompany);
        } else {
          handleFailure(response);
        }
        // handleScreenAfterLogin(data.email);
      })
      .catch((error) => {
        handleException(error);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="text-center h-100">
      <div className="form-input-container">
        <div className="form-input-group">
          <input
            className="form-input form-input-with-icon form-input-username"
            placeholder="first name"
            {...register('firstname', { required: true })}
          />
          <div className="error-text">{getErrorText(errors, 'firstname')}</div>
        </div>

        <div className="form-input-group">
          <input
            className="form-input form-input-with-icon form-input-username"
            placeholder="last name"
            {...register('lastname', { required: true })}
          />
          <div className="error-text">{getErrorText(errors, 'lastname')}</div>
        </div>

        <div className="form-input-group">
          <input
            className="form-input form-input-with-icon form-input-email"
            placeholder="email"
            {...register('email', { required: true })}
          />
          <div className="error-text">{getErrorText(errors, 'email')}</div>
        </div>

        <div className="form-input-group">
          <input
            className="form-input form-input-with-icon form-input-password"
            type="password"
            placeholder="password"
            {...register('password', { required: true })}
          />
          <div className="error-text">{getErrorText(errors, 'password')}</div>
        </div>

        <div className="form-input-group">
          <input
            className="form-input form-input-with-icon form-input-password"
            type="password"
            placeholder="confirm password"
            {...register('confirmpassword', { required: true })}
          />
          <div className="error-text">{getErrorText(errors, 'confirmpassword')}</div>
        </div>
      </div>

      <button className="submit-btn" type="submit">
        Sign Up
      </button>
    </form>
  );
};

export default Signup;
