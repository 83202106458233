import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import Api from '../../apis/ServerApis';
import { RoutePaths } from '../../navigation/RoutePath';
import { getErrorText } from '../../utility/helperFunctions';
import { handleException, handleResponseCode, showAlert } from '../../utility/util';
import { MainHeader } from './components/MainHeader';
import { PageContent } from '../../components/common';

const ResetPassword = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    // Api call for Login
    const url = `reset-password?email=${data.email}`;
    Api('POST', url, null)
      .then((response) => {
        if (handleResponseCode(response)) {
          showAlert('Success', response.message);
          navigate(RoutePaths.auth);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  return (
    <div className="container">
      <MainHeader title="Forgot Password" showBackButton />

      <PageContent>
        <form className="text-center h-100" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-input-container">
            <div className="form-input-group">
              <input
                className="form-input form-input-with-icon form-input-email"
                placeholder="email"
                {...register('email', { required: true })}
              />
              <div className="error-text">{getErrorText(errors, 'email')}</div>
            </div>
          </div>

          <p className="form-terms">Do you wanna go back?</p>
          <p className="form-terms-link">
            <Link to={-1}>Click Here</Link>
          </p>

          <button className="submit-btn" type="submit">
            Submit
          </button>
        </form>
      </PageContent>
    </div>
  );
};

export default ResetPassword;
