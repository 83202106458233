import React from 'react';
import styled from 'styled-components';

export const ButtonGradient = ({ icon, title, onClick }) => {
  return (
    <Container onClick={onClick} className="button-gradient">
      <Icon src={icon} style={{marginTop: '5px', marginBottom: '5px'}} />
      <Text>{title}</Text>
    </Container>
  );
};

const Container = styled.button`
  border-radius: 40px;
  border-width: 0px;
  width: 100%;
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  color: ${(props) => props.theme.colors.primary};
  text-transform: uppercase;
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
`;


const Text = styled.h2`
  color: ${(props) => props.theme.colors.primary};
  text-transform: uppercase;
`;
