// libraries
import styled from 'styled-components';

const RowSpacer = styled.div`
  width: ${(props) => props.size}rem;
`;

const ColumnSpacer = styled.div`
  height: ${(props) => props.size}rem;
`;

const FlexSpacer = styled.div`
  flex: ${(props) => props.size};
`;

export const Spacer = {
  Row: RowSpacer,
  Column: ColumnSpacer,
  Flex: FlexSpacer
};
