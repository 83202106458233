import React from 'react';
import styled from 'styled-components';

export const Button = ({ title, onClick, ...restProps }) => {
  return (
    <Container onClick={onClick} {...restProps}>
      <Text>{title}</Text>
    </Container>
  );
};

const Container = styled.button`
  background: ${(props) => props.theme.colors.primary};
  border-radius: 40px;
  border-width: 0px;
  width: 100%;
`;

const Text = styled.h3`
  color: ${(props) => props.theme.colors.secondary};
  text-transform: uppercase;
`;
