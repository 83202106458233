import React from 'react';
import api from '../../apis/ServerApis';

import { useNavigate } from 'react-router-dom';
import { handleException, handleFailure, showAlert } from '../../utility/util';
import { clearLocalStorage } from '../../utility/localStorage';
import { useEffect } from 'react';

import { SmallHeader } from '../../components/common/SmallHeader';
import { RoutePaths } from '../../navigation/RoutePath';

const ConfirmColleague = () => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log('hello');
    const confirmUrl = window.location.href;
    const splitUrl = confirmUrl.split('/');
    const url = `update-confirmation-new/${splitUrl[splitUrl.length - 2]}/${
      splitUrl[splitUrl.length - 1]
    }`;
    api('POST', url)
      .then((response) => {
        if (response.success) {
          showAlert('Success', 'Colleague is confirmed successfully');
          clearLocalStorage();
          navigate(RoutePaths.auth);
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  }, []);

  return (
    <div className='container'>
      <SmallHeader title='Confirm Details' />
    </div>
  );
};

export default ConfirmColleague;
