import React from 'react';
import styled from 'styled-components';
import { Spacer } from './Spacer';

export const PageContent = ({ children, headerType = 'medium' }) => {
  return (
    <Content headerType={headerType}>
      <div style={{ padding: '0 5px' }}>{children}</div>
      <Spacer.Column size={2} />
    </Content>
  );
};

export const Content = styled.div`
  background-color: ${(props) => '#262E48'};
  padding-top: 20px;
  max-width: 576px;
  width: 90%;
  align-self: center;
  text-align: center;
  margin: 20px auto;
  margin-top: 20px;
  border-radius: 20px;
`;
