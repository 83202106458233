import React from 'react';
import { showAlert } from '../utility/util';

export const getErrorText = (errors, inputName) => {
  if (errors[inputName]) {
    switch (errors[inputName].type) {
      case 'required':
        return 'This field is required!';

      default:
        return 'This filed is invalid!';
    }
  }
};

export const infoButton = (message, marginTop = 12) => {
  return (
    <span
      className='info-button info-button-right'
      style={{marginTop: marginTop + 'px'}}
      onClick={() => {
        showAlert('', message);
      }}
    >
      i
    </span>
  );
};