// library
import React from 'react';
import styled from 'styled-components';
import { NavigationBar } from '../../../components/common';
import { useNavigate } from 'react-router-dom';
import ArrowBack from '../../../assets/arrow-back.svg';

export const MainHeader = ({ title, showBackButton }) => {
  // variables
  const navigate = useNavigate();

  // returns
  return (
    <div className="row-between">
      {showBackButton ? (
        <a onClick={() => navigate(-1)} style={{ width: 60 }}>
          <NormalIcon src={ArrowBack} />
        </a>
      ) : (
        <div style={{ width: 60 }} />
      )}
      <MainText className="main-text">{title}</MainText>
      <NavigationBar />
    </div>
  );
};

const MainText = styled.h1`
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 10px;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
`;

const NormalIcon = styled.img`
  height: 1.5rem;
  width: 1.5rem;
  padding: 1em 1em;
`;
