import React from 'react';
import Select from 'react-select';
import { useTheme } from 'styled-components';

export const FormPopupSelect = (props) => {
  const { colors } = useTheme();
  
  return (
      <Select
      styles={{
        control: (provided, state) => ({
          ...provided,
          // border: `transparent`,
          textAlign: 'start',
          padding: '0.10rem 0.35rem',
          backgroundColor: "transparent",
          height: 52,
          
          borderColor: state.isFocused ? 'white' : '#76839d',
          // This line disable the blue border
          boxShadow: 'none'
        }),
        input: (provided) => ({
          ...provided,
          fontSize: '16px',
          fontFamily: 'Roboto, sans-serif',
          color: '#76839d',
        }),
        singleValue: (provided) => ({
          ...provided,
          fontSize: '16px',
          fontFamily: 'Roboto, sans-serif',
          color: 'white',
        }),
        placeholder: (provided) => ({
          ...provided,
          fontSize: '16px',
          fontFamily: 'Roboto, sans-serif',
          color: '#76839d',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: colors.primary,
        }),
      }}
      menuPlacement="top"
      {...props}
    />
  );
};
