// libraries
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import swal from 'sweetalert';

// misc
import { RoutePaths } from '../../navigation/RoutePath';

import { accessToken, shareToken } from '../../utility/constatnt';
import { clearLocalStorage, getLocalStorageItem, setLocalStorageItem } from '../../utility/localStorage';
import AccountIcon from '../../assets/account.png';
import ShareIcon from '../../assets/share.png';
import Api from '../../apis/ServerApis';
import { handleException, handleResponseCode } from '../../utility/util';
import { ShareDialog } from './ShareDialog';

export const NavigationBar = () => {
  const isUserLogin = getLocalStorageItem(accessToken) !== null ? true : false;
  const navigate = useNavigate();
  const location = useLocation()
  const [ openShareDialog, setOpenShareDialog ] = useState(false);
  const [ shareToken, setShareToken ] = useState('');

  const profileLocation = location.pathname === '/myprofile' ? true : false;

  // Logout button click event
  const logout = () => {
    swal('Are you sure you want to do logout?', {
      buttons: ['No', 'Yes'],
    }).then((willDelete) => {
      if (willDelete) {
        clearLocalStorage();
        navigate(RoutePaths.auth);
      }
    });
  };

  const handleShare = () => {
    // Api call for Share token
    const url = `share-token`;
    Api('POST', url, null)
      .then((response) => {
        if (handleResponseCode(response)) {
          if(response && response.data && response.data.share_token) {
            setShareToken(response.data.share_token);
            setOpenShareDialog(true);
          }
          else {
            swal('Some issue with share functionality. Please contact admin.');
          }
          // setLocalStorageItem(shareToken, response.data.share_token);
          // navigate(RoutePaths.publicProfile);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  }

  return (
    <NavigationBarContainer>
      {/* <Link to={RoutePaths.home}>
        <NormalIcon src={HomeIcon} />
      </Link> */}

      {/* <CenterIconContainer className="flex-center">
        {typeof centerIcon === 'function' ? centerIcon() : <CenterIcon src={centerIcon} />}
      </CenterIconContainer> */}

      <MultipleIconsContainer>
        {shareToken && (<ShareDialog open={openShareDialog} onClose={() => setOpenShareDialog(false)} shareToken={shareToken} />) }
        {isUserLogin && profileLocation ? (
          <NormalIcon src={ShareIcon} onClick={handleShare} />
        ) : 
        isUserLogin && !profileLocation ? (
          <Link to={RoutePaths.myProfile}>
          <NormalIcon src={AccountIcon} />
        </Link>
        ):(
          <Link to={RoutePaths.auth}>
            <NormalIcon src={AccountIcon} />
          </Link>
        )}
      </MultipleIconsContainer>
    </NavigationBarContainer>
  );
};

export const NavigationBarContainer = styled.div`
  padding: 1em 1em;
  display: flex;
  flex-direction: row;
  max-height: 40px;
  justify-content: space-between;
  align-items: center;
`;

const MultipleIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const NormalIcon = styled.img`
  height: 1.5rem;
  width: 1.5rem;
`;

const CenterIcon = styled.img`
  height: 4rem;
  width: 4rem;
`;

const CenterIconContainer = styled.div`
  background: ${(props) => props.theme.colors.secondary};
  padding: 0.1em;
  border-radius: 50%;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  height: 4rem;
  width: 4rem;
  left: 0;
  right: 0;
  text-align: center;
  bottom: -1.25rem;
`;
