import { showSpinner } from '../utility/util';
import { accessToken, jobBoardUrl } from '../utility/constatnt';
import { getLocalStorageItem } from '../utility/localStorage';

// Api call for POST Request
const Api = (method, apiname, apiParam) =>
  new Promise((resolve, reject) => {
    showSpinner(true);
    let headers = {};
    headers = {
      Authorization: `Bearer ${getLocalStorageItem(accessToken)}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };
    fetch(encodeURI(jobBoardUrl + apiname), {
      method: method,
      headers: headers,
      // mode: 'no-cors',
      body: JSON.stringify(apiParam)
      // data: JSON.stringify(apiParam)
    }).then((response) => {
      showSpinner(false);
      if (response.status >= 200 && response.status < 300) {
        if (response.headers.get('Content-Type') != null) {
          return resolve(response.json());
        }
      } else if (response.status === 500) {
        return reject(response.json());
      } else if (response.status >= 400 && response.status < 500) {
        return reject(response.status);
      } else {
        if (
          response.headers.get('Content-Type') != null &&
          response.headers.get('Content-Type') === 'application/json;charset=UTF-8'
        ) {
          return reject(response.statusText);
        }
        if (response.headers.get('Content-Type') != null) {
          return resolve(response.json());
        }

        return reject(response.blob());
      }
    });
  });

export default Api;
