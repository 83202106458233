// library
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import swal from 'sweetalert';

import { NavigationBar } from './NavigationBar';
import { RoutePaths } from '../../navigation/RoutePath';
import { accessToken } from '../../utility/constatnt';
import { clearLocalStorage, getLocalStorageItem } from '../../utility/localStorage';
import AccountIcon from '../../assets/account.png';

export const SmallHeader = ({ title }) => {
  const isUserLogin = getLocalStorageItem(accessToken) !== null ? true : false;
  const navigate = useNavigate();

  // Logout button click event
  const logout = () => {
    swal('Are you sure you want to do logout?', {
      buttons: ['No', 'Yes']
    }).then((willDelete) => {
      if (willDelete) {
        clearLocalStorage();
        navigate(RoutePaths.auth);
      }
    });
  };

  return (
    <div className="header header-small-height primary-bg">
      <NavigationBar />
      <div className="flex-center">
        <Heading>{title}</Heading>
      </div>
    </div>
  );
};

const Heading = styled.h1`
  font-weight: 500;
  // text-transform: lowercase;
  letter-spacing: 3px;
  color: ${(props) => props.theme.colors.secondary};
  text-align: center;
`;
