import React, { useEffect } from 'react';
import { useCallback } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { PageContent, Spacer } from '../../components/common';
import { RoutePaths } from '../../navigation/RoutePath';
import { userDetail } from '../../utility/constatnt';
import { getLocalStorageItem } from '../../utility/localStorage';
import { MainHeader } from './components/MainHeader';

const Auth = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const userDetails = JSON.parse(getLocalStorageItem(userDetail));
    if (userDetails !== null) {
      navigate(RoutePaths.addCompany);
    }
  }, []);

  const renderTabs = useCallback(() => {
    switch (pathname) {
      default:
        return (
          <div className="flex-row-center auth-tabs">
            <Link
              to={RoutePaths.auth}
              className={pathname === RoutePaths.auth ? 'nav-link active' : 'nav-link'}>
              login
            </Link>
            <Spacer.Row size={2} />
            <Link
              to={'/signup'}
              className={pathname === '/signup' ? 'nav-link active' : 'nav-link'}>
              signup
            </Link>
          </div>
        );
    }
  }, [pathname]);

  return (
    <div className="container">
      <MainHeader title="welcome" />
      <PageContent>
        {renderTabs()}
        <Outlet />
      </PageContent>
    </div>
  );
};

export default Auth;
