import React, { Suspense } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Auth from '../pages/auth/Auth';
import Login from '../pages/auth/Login';
// import ResetPassword from '../pages/auth/ResetPassword';
// import ResetPasswordComplete from '../pages/auth/ResetPasswordComplete';
import SignUp from '../pages/auth/SignUp';
import ResetPassword from '../pages/auth/ResetPassword';
import AddCompany from '../pages/company/AddCompany';
import AddColleague from '../pages/company/AddColleague';
import EditColleague from '../pages/company/EditColleague';
import ConfirmColleague from '../pages/company/ConfirmColleague';
import MyProfile from '../pages/account/MyProfile';
import PublicProfile from '../pages/account/PublicProfile';
import { RoutePaths } from './RoutePath';

const MainNavigator = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path={RoutePaths.auth} element={<Auth />}>
            <Route index element={<Login />} />
            <Route path={RoutePaths.signup.name} element={<SignUp />} />
          </Route>
          <Route exact path={RoutePaths.resetPassword} element={<ResetPassword />} />
          <Route exact path={RoutePaths.addCompany} element={<AddCompany />} />
          <Route exact path={RoutePaths.addColleague} element={<AddColleague />} />
          <Route exact path={RoutePaths.editColleague} element={<EditColleague />} />
          <Route exact path={RoutePaths.confirmColleague} element={<ConfirmColleague />} />
          <Route exact path={RoutePaths.myProfile} element={<MyProfile />} />
          <Route exact path={RoutePaths.publicProfile} element={<PublicProfile />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default MainNavigator;
