import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../apis/ServerApis';

import { checkEmailisValid, handleException, handleFailure, showAlert } from '../../utility/util';
import { getErrorText } from '../../utility/helperFunctions';
import { setLocalStorageItem } from '../../utility/localStorage';
import { accessToken, userDetail } from '../../utility/constatnt';
import { RoutePaths } from '../../navigation/RoutePath';

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    if (!checkEmailisValid(data.email)) {
      showAlert('Error', 'Email is invalid');
      return;
    }
    const loginParams = {
      email: data.email,
      password: data.password
    };

    // Api call for Login
    const url = 'login';
    api('POST', url, loginParams)
      .then((response) => {
        if (response.success) {
          response.data.user.password = data.password;
          setLocalStorageItem(accessToken, response.data.token);
          setLocalStorageItem(userDetail, JSON.stringify(response.data.user));
          navigate(RoutePaths.addCompany);
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  return (
    <form className="text-center h-100" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-input-container">
        <div className="form-input-group">
          <input
            className="form-input form-input-with-icon form-input-email"
            placeholder="email"
            {...register('email', { required: true })}
          />
          <div className="error-text">{getErrorText(errors, 'email')}</div>
        </div>

        <div className="form-input-group">
          <input
            className="form-input form-input-with-icon form-input-password"
            type="password"
            placeholder="password"
            {...register('password', { required: true })}
          />
          <div className="error-text">{getErrorText(errors, 'password')}</div>
        </div>
      </div>

      <p className="form-terms form-terms-link" style={{ marginBottom: '1em' }}>
        I agree with
        <a href="https://www.crowlr.com/en/privacy-policy/" target="_blank" rel="noreferrer">
          &nbsp;Privacy Policy&nbsp;
        </a>
        and
        <a href="https://www.crowlr.com/en/terms-of-service/" target="_blank" rel="noreferrer">
          &nbsp; Terms & Condition &nbsp;
        </a>
        <input style={{ marginTop: '9px' }} type="checkbox" required />
      </p>
      <p className="form-terms">Forgot Username/Password?</p>
      <p className="form-terms-link">
        <Link to={RoutePaths.resetPassword}>Click Here</Link>
      </p>

      <button className="submit-btn" type="submit">
        Log In
      </button>
    </form>
  );
};

export default Login;
