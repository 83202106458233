import React, { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Autocomplete from '@mui/material/Autocomplete';

import { RoutePaths } from '../../navigation/RoutePath';
import { SmallHeader } from '../../components/common/SmallHeader';
import { CustomCheckbox } from '../../components/common/CustomCheckbox';
import { getErrorText, infoButton } from '../../utility/helperFunctions';
import { getLocalStorageItem } from '../../utility/localStorage';
import { userDetail } from '../../utility/constatnt';
import { handleException, handleFailure, showAlert } from '../../utility/util';
import Api from '../../apis/ServerApis';
import { CssTextField } from './AddColleague';
import { MainHeader } from '../auth/components/MainHeader';
import { PageContent } from '../../components/common';

const AddCompany = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [companyList, setCompanyList] = useState([]);
  const [companyName, setCompanyName] = useState({});
  const [inputValue, setInputValue] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isCurrentCompany, setIsCurrentCompany] = useState(false);
  const [location, setLocation] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // if (JSON.parse(getLocalStorageItem(userDetail)) === null) {
    //   navigate(RoutePaths.auth);
    // }
    const url = 'get-companies?name=';
    Api('GET', url)
      .then((response) => {
        if (response.success) {
          if (response.data.length !== 0) {
            console.log(response.data);
            setCompanyList(response.data);
          }
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  }, []);

  // Set date format
  const setDateFormat = (val) => {
    const date = new Date(val);
    if(date.toString() !== "Invalid Date") {
      date.setHours(23);
      date.setMinutes(59);
      return date.toISOString().slice(0, 10);
    }
  };

  // Handle SignUp
  const onSubmit = (data) => {
    console.log(JSON.parse(getLocalStorageItem(userDetail)));
    const userDetails = JSON.parse(getLocalStorageItem(userDetail));
    if (inputValue === '') {
      showAlert('Error', 'company name is required');
      return;
    }

    if (location === '' || location === null) {
      showAlert('Error', 'location: city,state is required');
      return;
    }
    const companyParams = {
      company: {
        user_id: userDetails.id,
        name: inputValue,
        start_date: startDate,
        end_date: isCurrentCompany ? '' : endDate,
        is_current_company: isCurrentCompany,
        location: location
      }
    };
    navigate(RoutePaths.addColleague, { state: companyParams });
    showAlert('Success', 'Company added successfully');
  };

  let endDateOfCompany = '';
  if (!isCurrentCompany) {
    endDateOfCompany = (
      <>
      {infoButton("If this is NOT the current company you are working for, but a previous one, please add the end date.", 27)}
      <div className="form-input-group text-left full-width">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="End Date"
            inputFormat="DD/MM/YYYY"
            value={endDate}
            minDate={startDate}
            onChange={(val) => setEndDate(setDateFormat(val))}
            renderInput={(props) => (
              <CssTextField
                {...props}
                className="form-input"
                variant="outlined"
                margin="normal"
              />
            )}
          />
        </LocalizationProvider>
      </div>
      </>
    );
  }

  return (
    <div className="container">
      <MainHeader title="Add Company" />

      <PageContent>
      <form onSubmit={handleSubmit(onSubmit)} className="text-center h-100">
        <div className="form-input-container">
          {infoButton("This field allows you to create or select an existing company for which you have worked and you are planning to associate current or previous colleagues responsibilities.")}
          <div className="form-input-group">
            <Autocomplete
              // id="free-solo-demo"
               freeSolo
              name="companyname"
              options={companyList}
              value={companyName}
              onChange={(event, newValue) => {
                setCompanyName(newValue);
                if (typeof newValue === 'object' && newValue) {
                  if(newValue.location !== null){
                    setLocation(newValue.location)
                  }
                }
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                
                setInputValue(newInputValue);
                
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    <span>{option.company_name}</span>
                    <span>&nbsp;</span>
                    {option.location !== null ? '(' + option.location + ')' : ''}
                  </li>
                );
              }}
              getOptionLabel={(option) => option.company_name || ''}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  value={inputValue}
                  className="form-input"
                  label="Company Name"
                  variant="outlined"
                />
              )}
            />
            <div className="error-text">{getErrorText(errors, 'companyname')}</div>
          </div>

          {infoButton("Please indicate the time period for which you have been working for the selected company.", 27)}
          <div className="form-input-group text-left full-width">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Start Date"
                inputFormat="DD/MM/YYYY"
                value={startDate}
                onChange={(val) => {
                  setStartDate(setDateFormat(val));
                }}
                renderInput={(props) => (
                  <CssTextField
                    {...props}
                    className="form-input"
                    variant="outlined"
                    margin="normal"
                  />
                )}
              />
            </LocalizationProvider>
          </div>

          <div className="form-input-group">
            <CustomCheckbox
              label="Is it your current company ?"
              value={isCurrentCompany}
              onChange={setIsCurrentCompany}
            />
          </div>

          {endDateOfCompany}

          {infoButton("Please state the City and the Country, for the Created or Selected company.", 27)}
          <div className="form-input-group full-width">
            <CssTextField
              className="form-input"
              variant="outlined"
              label="Location : City, Country"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              margin="normal"
            />
            <div className="error-text">{getErrorText(errors, 'location')}</div>
          </div>
        </div>

        <button className="submit-btn" type="submit">
          Save
        </button>
      </form>
      </PageContent>
    </div>
  );
};

export default AddCompany;
