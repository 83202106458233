// libraries
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

// components
import { PageContent } from '../../components/common';
import { MainHeader } from '../auth/components/MainHeader';

// misc
import { getLocalStorageItem } from '../../utility/localStorage';
import { shareToken } from '../../utility/constatnt';
import { generateInitialImage, handleException, handleResponseCode, showAlert } from '../../utility/util';
import zeroStar from '../../assets/star_zero.png';
import oneStar from '../../assets/star_one.png';
import threeStar from '../../assets/star_three.png';
import fiveStar from '../../assets/star_five.png';
import Api from '../../apis/ServerApis';

const PublicProfile = () => {
  const params = useParams();
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    const shareTokens = params.token;
    if(shareTokens) {
      const url = `user-by-token/${shareTokens}`;
      Api('GET', url)
      .then((response) => {
        if (handleResponseCode(response)) {
          setUserDetails(response.data);
        }
      })
      .catch((error) => {
        handleException(error);
      });
    }
    else {
      showAlert('Error', 'Something went wrong to fetch profile.');
    }
    
  }, [])

  // Show profile Picture based on condition
  const handleProfilePicture = () => {
    if (userDetails.total_confirmed_requests < 3){
      return generateInitialImage(userDetails.name);
    } else {
      const referalScore = parseFloat(userDetails.referral_score);
      if(referalScore <= 20 ){
        return zeroStar;
      } else if(referalScore <= 50){
        return oneStar;
      } else if(referalScore <= 80){
        return threeStar;
      } else {
        return fiveStar;
      }
    }
  }

  if (userDetails.referral_score === undefined) {
    userDetails.referral_score = '';
  }
  userDetails.referral_score = Math.round((userDetails.referral_score).replace('%', '')) + '%';
  console.log(userDetails.name);

  return (
    <div className="container">
      <MainHeader showBackButton title="Profile" />

      <PageContent>
        <img
          // src={generateInitialImage(userDetails.name)}
          src={handleProfilePicture()}
          width={100}
          height={100}
          style={{ borderRadius: '50%' }}
        />
        <h3 className="profile-name capitalized">{userDetails.name}</h3>
        <p className="profile-email">{userDetails.email}</p>

        <div className="profile-row-with-space">
          <div className="profile-row-section">
            <div className="normal-text">
              {userDetails.total_confirmed_requests + userDetails.total_edited_requests}/{userDetails.total_requests}
            </div>
            <div className="profile-sub-text">
              Based on{' '}
              <span
                className="info-button"
                onClick={() => {
                  showAlert('', 'The ratio of accepted, deleted, or edited relation requests relative to the total number of relation requests.');
                }}>
                i
              </span>
            </div>
          </div>
          <div className="profile-row-section">
            <div className="normal-text">{userDetails.score}</div>
            <div className="profile-sub-text">
              Score
              {/* <span
                className="info-button"
                onClick={() => {
                  showAlert('', 'Score info button clicked.');
                }}>
                i
              </span> */}
            </div>
          </div>
          <div className="profile-row-section">
            <div className="normal-text">{userDetails.referral_score}</div>
            <div className="profile-sub-text">
              Score in %{' '}
              <span
                className="info-button"
                onClick={() => {
                  showAlert('', 'An 80% score means your relations requests "edit" and "refusal" has made your score dropped by 20%.');
                }}>
                i
              </span>
            </div>
          </div>
        </div>

        <br />
        <br />
      </PageContent>
    </div>
  );
};

export default PublicProfile;
