export const jobBoardUrl = 'https://admin.crowlr.com/api/'; // Live/Production server url

export const accessToken = 'accessToken';
export const tokenType = 'tokenType';
export const userDetail = 'userDetail';
export const shareToken = 'shareToken';
export const functionDepartment = [
{ value: 'Corporate', label: 'Corporate' },
{ value: 'Engineering', label: 'Engineering' },
{ value: 'Facilities', label: 'Facilities' },
{ value: 'Finance', label: 'Finance' },
{ value: 'General Office', label: 'General Office' },
{ value: 'HR', label: 'HR' },
{ value: 'IS', label: 'IS' },
{ value: 'IT', label: 'IT' },
{ value: 'Legal', label: 'Legal' },
{ value: 'Manufacturing', label: 'Manufacturing' },
{ value: 'Marketing', label: 'Marketing' },
{ value: 'Media', label: 'Media' },
{ value: 'PMO, Projects', label: 'PMO, Projects' },
{ value: 'Purchasing', label: 'Purchasing' },
{ value: 'R&D', label: 'R&D' },
{ value: 'Sales', label: 'Sales'},
{ value: 'Store Operations', label: 'Store Operations'},
{ value: 'Supply Chain', label: 'Supply Chain' },
];

export const levelDesignation = [
{ value: 'Management Team', label: 'Management Team' },
{ value: 'Senior Manager', label: 'Senior Manager' },
{ value: 'Manager', label: 'Manager' },
{ value: 'Senior', label: 'Senior' },
{ value: 'Specialist', label: 'Specialist' },
{ value: 'Junior', label: 'Junior' },
{ value: 'Trainee', label: 'Trainee' }
];

export const peerLevel = [
{ value: 'N-2', label: 'N-2' },
{ value: 'N-1', label: 'N-1' },
{ value: 'N', label: 'N' },
{ value: 'N+1', label: 'N+1' },
{ value: 'N+2', label: 'N+2' }
];

export const colleagueRole = [
{ value: 'Corporate', label: 'Administration' },
{ value: 'Corporate', label: 'Archive' },
{ value: 'Corporate', label: 'Compliance' },
{ value: 'Corporate', label: 'Document' },
{ value: 'Corporate', label: 'Environmental' },
{ value: 'Corporate', label: 'Executive office' },
{ value: 'Corporate', label: 'Goverance' },
{ value: 'Corporate', label: 'Services' },
{ value: 'Engineering', label: 'Automation' },
{ value: 'Engineering', label: 'Engineering, Architecture' },
{ value: 'Engineering', label: 'Capital projects' },
{ value: 'Engineering', label: 'Construction, installation' },
{ value: 'Engineering', label: 'Continuous improvement' },
{ value: 'Engineering', label: 'Contractors' },
{ value: 'Engineering', label: 'Design, construction' },
{ value: 'Engineering', label: 'Energy' },
{ value: 'Engineering', label: 'Factory maintenance' },
{ value: 'Engineering', label: 'Industrial services' },
{ value: 'Engineering', label: 'Packaging' },
{ value: 'Engineering', label: 'Preventive maintenance' },
{ value: 'Engineering', label: 'Process re-engineering' },
{ value: 'Engineering', label: 'Product' },
{ value: 'Engineering', label: 'Repair' },
{ value: 'Engineering', label: 'Routine maintenance' },
{ value: 'Engineering', label: 'Technical' },
{ value: 'Engineering', label: 'Mfg, Technology' },
{ value: 'Engineering', label: 'METHODS, PROCESS' },
{ value: 'Engineering', label: 'Quality' },
{ value: 'Engineering', label: 'Validation' },
{ value: 'Facilities', label: 'Equipment' },
{ value: 'Facilities', label: 'Vehicules' },
{ value: 'Facilities', label: 'Communications' },
{ value: 'Facilities', label: 'Building' },
{ value: 'Facilities', label: 'Property Portfolio' },
{ value: 'Facilities', label: 'Maintenance' },
{ value: 'Facilities', label: 'Administration' },
{ value: 'Facilities', label: 'Utilities' },
{ value: 'Facilities', label: 'Furnitures' },
{ value: 'Facilities', label: 'Operations' },
{ value: 'Facilities', label: '' },
{ value: 'Finance', label: 'Accounting, Reporting' },
{ value: 'Finance', label: 'Auditing' },
{ value: 'Finance', label: 'Biling' },
{ value: 'Finance', label: 'Bookkeeper' },
{ value: 'Finance', label: 'Budget' },
{ value: 'Finance', label: 'Collections' },
{ value: 'Finance', label: 'Controlling' },
{ value: 'Finance', label: 'Currencies, Commodities' },
{ value: 'Finance', label: 'Financial' },
{ value: 'Finance', label: 'Investment' },
{ value: 'Finance', label: 'Risk' },
{ value: 'Finance', label: 'Systems' },
{ value: 'Finance', label: 'Support' },
{ value: 'Finance', label: 'Analytics, Performance' },
{ value: 'General Office', label: 'Administration' },
{ value: 'General Office', label: 'Business' },
{ value: 'General Office', label: 'Typist' },
{ value: 'General Office', label: 'Executive' },
{ value: 'General Office', label: 'Mail' },
{ value: 'General Office', label: 'Office' },
{ value: 'General Office', label: 'Permit' },
{ value: 'General Office', label: 'Receptionist' },
{ value: 'General Office', label: 'Travel' },
{ value: 'HR', label: 'Analytics, reporting' },
{ value: 'HR', label: 'Benefits' },
{ value: 'HR', label: 'Business Partnering' },
{ value: 'HR', label: 'Career, succession planning' },
{ value: 'HR', label: 'Certification' },
{ value: 'HR', label: 'Compensation' },
{ value: 'HR', label: 'Compliance' },
{ value: 'HR', label: 'Continuous improvement' },
{ value: 'HR', label: 'Contracts' },
{ value: 'HR', label: 'Development' },
{ value: 'HR', label: 'Employment' },
{ value: 'HR', label: 'Labor Relations' },
{ value: 'HR', label: 'Organizational' },
{ value: 'HR', label: 'Payroll, Incentives' },
{ value: 'HR', label: 'Performance, Appraisal' },
{ value: 'HR', label: 'Personnel records' },
{ value: 'HR', label: 'Policies' },
{ value: 'HR', label: 'Program, Project' },
{ value: 'HR', label: 'Recruitment' },
{ value: 'HR', label: 'Regulations, compliance' },
{ value: 'HR', label: 'Relations' },
{ value: 'HR', label: 'Relocation, mobility' },
{ value: 'HR', label: 'Safety' },
{ value: 'HR', label: 'Staffing' },
{ value: 'HR', label: 'Talent' },
{ value: 'HR', label: 'Training' },
{ value: 'HR', label: 'Travel, Expenses' },
{ value: 'IT', label: 'Administration' },
{ value: 'IT', label: 'Analytics, reporting' },
{ value: 'IT', label: 'Applications' },
{ value: 'IT', label: 'Architecture' },
{ value: 'IT', label: 'Business' },
{ value: 'IT', label: 'Communications' },
{ value: 'IT', label: 'Continuous improvement' },
{ value: 'IT', label: 'Data' },
{ value: 'IT', label: 'Data Protection' },
{ value: 'IT', label: 'Database' },
{ value: 'IT', label: 'Development' },
{ value: 'IT', label: 'Help Desk, Support' },
{ value: 'IT', label: 'Policies, Process' },
{ value: 'IT', label: 'Program, Project' },
{ value: 'IT', label: 'Quality Assurance' },
{ value: 'IT', label: 'Security' },
{ value: 'IT', label: 'Standards, Guidelines' },
{ value: 'IT', label: 'Support' },
{ value: 'IT', label: 'Technology' },
{ value: 'IT', label: 'Telecommunications' },
{ value: 'IT', label: 'Training' },
{ value: 'IT', label: 'Infrastructure' },
{ value: 'IT', label: 'Account mgt' },
{ value: 'IS', label: 'Administration' },
{ value: 'IS', label: 'Analytics, reporting' },
{ value: 'IS', label: 'Applications' },
{ value: 'IS', label: 'Architecture' },
{ value: 'IS', label: 'Business' },
{ value: 'IS', label: 'Communications' },
{ value: 'IS', label: 'Continuous improvement' },
{ value: 'IS', label: 'Data' },
{ value: 'IS', label: 'Database' },
{ value: 'IS', label: 'Enterprise' },
{ value: 'IS', label: 'Help Desk, Support' },
{ value: 'IS', label: 'Industry' },
{ value: 'IS', label: 'MasterData' },
{ value: 'IS', label: 'Policies, Process' },
{ value: 'IS', label: 'Program, Project' },
{ value: 'IS', label: 'Quality Assurance' },
{ value: 'IS', label: 'Security' },
{ value: 'IS', label: 'Standards, Guidelines' },
{ value: 'IS', label: 'Support' },
{ value: 'IS', label: 'Systems' },
{ value: 'IS', label: 'Technology' },
{ value: 'IS', label: 'Telecommunications' },
{ value: 'IS', label: 'Training' },
{ value: 'Legal', label: 'Archives' },
{ value: 'Legal', label: 'Attorney' },
{ value: 'Legal', label: 'Commercial law' },
{ value: 'Legal', label: 'Compliance' },
{ value: 'Legal', label: 'Contracts' },
{ value: 'Legal', label: 'Environmental, heath, safety' },
{ value: 'Legal', label: 'Ethics, Compliance' },
{ value: 'Legal', label: 'Financial law' },
{ value: 'Legal', label: 'Intellectual property' },
{ value: 'Legal', label: 'Legal' },
{ value: 'Legal', label: 'Legal advising' },
{ value: 'Legal', label: 'Licensing, Patents' },
{ value: 'Legal', label: 'Litigation' },
{ value: 'Legal', label: 'Paralegal' },
{ value: 'Legal', label: 'Trademark' },
{ value: 'Manufacturing', label: 'Continuous Improvement' },
{ value: 'Manufacturing', label: 'Development' },
{ value: 'Manufacturing', label: 'Equipment' },
{ value: 'Manufacturing', label: 'Facilities' },
{ value: 'Manufacturing', label: 'Handling' },
{ value: 'Manufacturing', label: 'Health, Safety' },
{ value: 'Manufacturing', label: 'Installation' },
{ value: 'Manufacturing', label: 'Machinery' },
{ value: 'Manufacturing', label: 'Maintenance' },
{ value: 'Manufacturing', label: 'Material' },
{ value: 'Manufacturing', label: 'Packaging' },
{ value: 'Manufacturing', label: 'Plant, Factory' },
{ value: 'Manufacturing', label: 'Production' },
{ value: 'Manufacturing', label: 'Quality' },
{ value: 'Manufacturing', label: 'Repair' },
{ value: 'Manufacturing', label: 'Storage, Warehousing' },
{ value: 'Manufacturing', label: 'Technical' },
{ value: 'Manufacturing', label: 'Other' },
{ value: 'Manufacturing', label: 'Operational Excellence' },
{ value: 'Manufacturing', label: 'Method' },
{ value: 'Manufacturing', label: 'Engineering' },
{ value: 'Marketing', label: 'Advertising' },
{ value: 'Marketing', label: 'Analytics, reporting' },
{ value: 'Marketing', label: 'Automation' },
{ value: 'Marketing', label: 'Brand' },
{ value: 'Marketing', label: 'Business Intelligence' },
{ value: 'Marketing', label: 'Campaign' },
{ value: 'Marketing', label: 'Category' },
{ value: 'Marketing', label: 'Channel' },
{ value: 'Marketing', label: 'Communication' },
{ value: 'Marketing', label: 'Consumer' },
{ value: 'Marketing', label: 'Content' },
{ value: 'Marketing', label: 'Continuous improvement' },
{ value: 'Marketing', label: 'Corporate' },
{ value: 'Marketing', label: 'Customer' },
{ value: 'Marketing', label: 'Development' },
{ value: 'Marketing', label: 'Digital' },
{ value: 'Marketing', label: 'Direct' },
{ value: 'Marketing', label: 'Distribution' },
{ value: 'Marketing', label: 'Events, sponsorships' },
{ value: 'Marketing', label: 'Market Research' },
{ value: 'Marketing', label: 'Merchandising' },
{ value: 'Marketing', label: 'Mobile' },
{ value: 'Marketing', label: 'Partnerships, Affiliate' },
{ value: 'Marketing', label: 'Pricing' },
{ value: 'Marketing', label: 'Product' },
{ value: 'Marketing', label: 'Program, Project' },
{ value: 'Marketing', label: 'Public Relations' },
{ value: 'Marketing', label: 'Sales' },
{ value: 'Marketing', label: 'SEO/SEM' },
{ value: 'Marketing', label: 'Social Media' },
{ value: 'Marketing', label: 'Strategy' },
{ value: 'Marketing', label: 'Trade' },
{ value: 'Media', label: 'Advertising, Account' },
{ value: 'Media', label: 'Advertising, Traffic' },
{ value: 'Media', label: 'Camera' },
{ value: 'Media', label: 'Director' },
{ value: 'Media', label: 'Editor' },
{ value: 'Purchasing', label: 'Sourcing Strategy' },
{ value: 'Purchasing', label: 'Strategic Procurement' },
{ value: 'Purchasing', label: 'Sourcing Indirect' },
{ value: 'Purchasing', label: 'Sourcing Direct' },
{ value: 'Purchasing', label: 'Risque' },
{ value: 'Purchasing', label: 'Srm' },
{ value: 'Purchasing', label: 'Catégorie' },
{ value: 'Purchasing', label: 'Spend' },
{ value: 'Purchasing', label: 'Fournisseur' },
{ value: 'Purchasing', label: 'Production Sous-Traitance' },
{ value: 'Purchasing', label: 'Achat' },
{ value: 'Purchasing', label: 'Qualité' },
{ value: 'Purchasing', label: 'Operational Excellence' },
{ value: 'Purchasing', label: 'Analytics Reporting' },
{ value: 'Purchasing', label: 'Direction' },
{ value: 'Purchasing', label: 'Emballage' },
{ value: 'Purchasing', label: 'Approvisionnement' },
{ value: 'Purchasing', label: 'Programmes Projets' },
{ value: 'Purchasing', label: 'Matières Premières' },
{ value: 'Purchasing', label: 'Sourcing' },
{ value: 'Purchasing', label: 'Vm Central' },
{ value: 'Purchasing', label: 'Merchandising' },
{ value: 'Purchasing', label: 'Vm Design' },
{ value: 'Purchasing', label: 'Planification' },
{ value: 'Purchasing', label: 'Conception' },
{ value: 'R&D', label: 'Produit' },
{ value: 'R&D', label: 'Corporate' },
{ value: 'R&D', label: 'Développement' },
{ value: 'R&D', label: 'Pharma Early Stage' },
{ value: 'R&D', label: 'Support' },
{ value: 'R&D', label: 'Hardware' },
{ value: 'R&D', label: 'Programmes Projets' },
{ value: 'R&D', label: 'Technique' },
{ value: 'R&D', label: 'Recherche' },
{ value: 'R&D', label: 'Logiciel' },
{ value: 'R&D', label: 'Systèmes' },
{ value: 'R&D', label: 'Publication' },
{ value: 'R&D', label: 'Validation Test' },
{ value: 'R&D', label: 'Applications' },
{ value: 'R&D', label: 'Emballage Etiquetage' },
{ value: 'R&D', label: 'Device' },
{ value: 'R&D', label: 'Équipement' },
{ value: 'R&D', label: 'Processing Tech' },
{ value: 'R&D', label: 'Production' },
{ value: 'R&D', label: 'Service' },
{ value: 'R&D', label: 'Information' },
{ value: 'R&D', label: 'Qualité Sécurité' },
{ value: 'R&D', label: 'Operational Excellence' },
{ value: 'R&D', label: 'Emballage' },
{ value: 'Sales', label: 'Technical' },
{ value: 'Sales', label: 'Gms' },
{ value: 'Sales', label: 'Analytics Reporting' },
{ value: 'Sales', label: 'Campagne' },
{ value: 'Sales', label: 'Catégorie' },
{ value: 'Sales', label: 'Canal' },
{ value: 'Sales', label: 'Consommateur' },
{ value: 'Sales', label: 'Operational Excellence' },
{ value: 'Sales', label: 'Client' },
{ value: 'Sales', label: 'Développement' },
{ value: 'Sales', label: 'Direct' },
{ value: 'Sales', label: 'Distributeurs' },
{ value: 'Sales', label: 'Field' },
{ value: 'Sales', label: 'Merchandising' },
{ value: 'Sales', label: 'Organisation' },
{ value: 'Sales', label: 'Pricing' },
{ value: 'Sales', label: 'Produits' },
{ value: 'Sales', label: 'Commercial Sédentaire' },
{ value: 'Sales', label: 'Strategie' },
{ value: 'Sales', label: 'Support Service' },
{ value: 'Sales', label: 'Trade' },
{ value: 'Sales', label: 'Formations' },
{ value: 'Sales', label: 'Force De Vente' },
{ value: 'Sales', label: 'Opérations' },
{ value: 'Sales', label: 'App Technique' },
{ value: 'Sales', label: 'Sav' },
{ value: 'Sales', label: 'Marque' },
{ value: 'Sales', label: 'Exportation' },
{ value: 'Sales', label: 'Demande' },
{ value: 'Sales', label: 'Direction' },
{ value: 'Sales', label: 'Planification' },
{ value: 'Sales', label: 'Stratégies Process' },
{ value: 'Sales', label: 'Programmes Projets' },
{ value: 'Sales', label: 'Reporting' },
{ value: 'Sales', label: 'Approvisionnement' },
{ value: 'Sales', label: 'Tele Sales' },
{ value: 'Sales', label: 'Trade Marketing' },
{ value: 'Sales', label: 'Gestion De Compte' },
{ value: 'Sales', label: 'Ventes Zone' },
{ value: 'Sales', label: 'Technico-Commercial Sédentaire' },
{ value: 'Sales', label: 'Commerce Électronique' },
{ value: 'Sales', label: 'Comptes-Clés' },
{ value: 'Sales', label: 'Grands Comptes' },
{ value: 'Sales', label: 'Ventes Nationales' },
{ value: 'Sales', label: 'Ventes Régionales' },
{ value: 'Sales', label: 'Ventes Secteur' },
{ value: 'Sales', label: 'Van Sales' },
{ value: 'Sales', label: 'Grossiste' },
{ value: 'Sales', label: 'Vente Au Détail' },
{ value: 'Sales', label: 'Pré-Vente' },
{ value: 'Sales', label: 'Branche' },
{ value: 'Sales', label: 'Vm Field' },
{ value: 'Sales', label: 'Opérations Magasin' },
{ value: 'Sales', label: 'Vm Magasin' },
{ value: 'Sales', label: 'Vm Central' },
{ value: 'Sales', label: 'B2B' },
{ value: 'Sales', label: 'B2C' },
{ value: 'Sales', label: 'Oreca' },
{ value: 'Sales', label: 'Gsb' },
{ value: 'Sales', label: 'B2G' },
{ value: 'Sales', label: 'Gsa' },
{ value: 'Sales', label: 'Devenir Beta Testeur' },
{ value: 'Sales', label: 'Technico-Commercial' },
{ value: 'Sales', label: 'Représentant Commercial' },
{ value: 'Store Operations', label: 'Technique' },
{ value: 'Store Operations', label: 'Entretien' },
{ value: 'Store Operations', label: 'Construction Installation' },
{ value: 'Store Operations', label: 'Repairs' },
{ value: 'Store Operations', label: 'Conception Construction' },
{ value: 'Store Operations', label: 'Operational Excellence' },
{ value: 'Store Operations', label: 'Departement' },
{ value: 'Store Operations', label: 'Display' },
{ value: 'Store Operations', label: 'Linear' },
{ value: 'Store Operations', label: 'Marchandise' },
{ value: 'Store Operations', label: 'Ventes' },
{ value: 'Store Operations', label: 'Scanning' },
{ value: 'Store Operations', label: 'Service Desk' },
{ value: 'Store Operations', label: 'Magasin' },
{ value: 'Store Operations', label: 'Visuel' },
{ value: 'Supply Chain', label: 'Analytics Reporting' },
{ value: 'Supply Chain', label: 'Operational Excellence' },
{ value: 'Supply Chain', label: 'Customer Facing' },
{ value: 'Supply Chain', label: 'Service Clients' },
{ value: 'Supply Chain', label: 'Demande' },
{ value: 'Supply Chain', label: 'Demande Supply' },
{ value: 'Supply Chain', label: 'Approvisionnement' },
{ value: 'Supply Chain', label: 'Inventaire' },
{ value: 'Supply Chain', label: 'Direction' },
{ value: 'Supply Chain', label: 'Logistique Distribution' },
{ value: 'Supply Chain', label: 'Stratégies Processus' },
{ value: 'Supply Chain', label: 'Cycle De Vie Du Produit' },
{ value: 'Supply Chain', label: 'Programmes Projets' },
{ value: 'Supply Chain', label: 'Recherche' },
{ value: 'Supply Chain', label: 'Directives Normes' },
{ value: 'Supply Chain', label: 'Systèmes Support' },
{ value: 'Supply Chain', label: 'Entreposage' },
{ value: 'Supply Chain', label: 'Material Managment' },
{ value: 'Supply Chain', label: 'Mps' },
{ value: 'Supply Chain', label: 'Reporting' },
{ value: 'Supply Chain', label: 'Supply Network' },
{ value: 'Supply Chain', label: 'Flotte' },
{ value: 'Supply Chain', label: 'Import Export' },
{ value: 'Supply Chain', label: 'Pic S&Op Siop' },
{ value: 'PMO, Projects', label: 'Gestion De Projet' },
{ value: 'PMO, Projects', label: 'Programmes Projets' },
{ value: 'PMO, Projects', label: 'Gestion De Portefeuille' },
{ value: 'PMO, Projects', label: 'Département' },
{ value: 'PMO, Projects', label: 'Business Unit' },
{ value: 'PMO, Projects', label: 'Gouvernance' },
{ value: 'PMO, Projects', label: 'Projets Ressources' },
{ value: 'PMO, Projects', label: 'Personnel' },
{ value: 'PMO, Projects', label: 'Aps Mes' },
{ value: 'PMO, Projects', label: 'Contrôle' },
{ value: 'PMO, Projects', label: 'Delivery Life Cycle' },
{ value: 'PMO, Projects', label: 'Lean Six Sigma' },
{ value: 'PMO, Projects', label: 'Npd Innovation' },
{ value: 'PMO, Projects', label: 'O2C' },
{ value: 'PMO, Projects', label: 'P2P' },
{ value: 'PMO, Projects', label: 'Pmo' },
{ value: 'PMO, Projects', label: 'Cycle De Vie Du Produit' },
{ value: 'PMO, Projects', label: 'Projets Contrôle' },
{ value: 'PMO, Projects', label: 'Steering Committee' },
{ value: 'PMO, Projects', label: 'Support' },
{ value: 'PMO, Projects', label: 'Durabilité' },
{ value: 'PMO, Projects', label: 'Moa Po' },
{ value: 'PMO, Projects', label: 'Amoa' },
{ value: 'PMO, Projects', label: 'Moe' },
{ value: 'PMO, Projects', label: 'Scrum' }
];
