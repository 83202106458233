// libraries
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Marquee from "react-fast-marquee";

// components
import { PageContent } from '../../components/common';
import { MainHeader } from '../auth/components/MainHeader';

// misc
import { RoutePaths } from '../../navigation/RoutePath';
import { getLocalStorageItem, clearLocalStorage, setLocalStorageItem } from '../../utility/localStorage';
import { userDetail } from '../../utility/constatnt';
import { generateInitialImage, handleException, handleFailure, showAlert } from '../../utility/util';
import zeroStar from '../../assets/star_zero.png';
import oneStar from '../../assets/star_one.png';
import threeStar from '../../assets/star_three.png';
import fiveStar from '../../assets/star_five.png';
import Api from '../../apis/ServerApis';

const MyProfile = () => {
  // variables
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(JSON.parse(getLocalStorageItem(userDetail)));

  useEffect(() => {

    // Api call for GetUserProfile
    const url = `get-user/${userDetails.id}`
    Api('GET', url)
      .then((response) => {
        if (response.success) {
          response.data.password = userDetails.password;
          setLocalStorageItem(userDetail, JSON.stringify(response.data));
          setUserDetails(response.data);
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  },[]);

  // Handle Logout
  const handleLogout = () => {
    clearLocalStorage();
    navigate(RoutePaths.auth);
  };

  // Handle Feedback
  const handleFeedback = () => {
    const authToken = "user_id=" + userDetails.id;
    if(authToken !== null){
      window.open(
        'https://admin.crowlr.com/feedback-form?auth_token=' +
          btoa(btoa(authToken)),
        '_blank'
      );
    }
  };

  // Handle More Feature
  const handleMoreFeature = () => {
    console.log('https://admin.crowlr.com/candidate/dashboard/login?token=' + btoa(btoa(userDetails.email + ':' + userDetails.password)));
    window.open(
      'https://admin.crowlr.com/candidate/dashboard/login?token=' +
        btoa(btoa(userDetails.email + ':' + userDetails.password)),
      '_blank'
    );
  };

  // Show profile Picture based on condition
  const handleProfilePicture = () => {
    if (userDetails.total_confirmed_requests < 3){
      return generateInitialImage(userDetails.name);
    } else {
      const referalScore = parseFloat(userDetails.referral_score);
      if(referalScore <= 20 ){
        return zeroStar;
      } else if(referalScore <= 50){
        return oneStar;
      } else if(referalScore <= 80){
        return threeStar;
      } else {
        return fiveStar;
      }
    }
  }

  userDetails.referral_score = Math.round((userDetails.referral_score).replace('%', '')) + '%';

  return (
    <div className="container">
      <MainHeader showBackButton title="My Profile" />

      <PageContent>
        <img
          src={handleProfilePicture()}
          width={100}
          height={100}
          style={{ borderRadius: '50%' }}
        />
        <h3 className="profile-name capitalized">{userDetails.name}</h3>
        <p className="profile-email">{userDetails.email}</p>

        <div className="profile-row-with-space">
          <div className="profile-row-section">
            <div className="normal-text">
              {userDetails.total_confirmed_requests + userDetails.total_edited_requests}/{userDetails.total_requests}
            </div>
            <div className="profile-sub-text">
              Based on{' '}
              <span
                className="info-button"
                onClick={() => {
                  showAlert('', 'The ratio of accepted, deleted, or edited relation requests relative to the total number of relation requests.');
                }}>
                i
              </span>
            </div>
          </div>
          <div className="profile-row-section">
            <div className="normal-text">{userDetails.score}</div>
            <div className="profile-sub-text">
              Score
              {/* <span
                className="info-button"
                onClick={() => {
                  showAlert('', 'Score info button clicked.');
                }}>
                i
              </span> */}
            </div>
          </div>
          <div className="profile-row-section">
            <div className="normal-text">{userDetails.referral_score}</div>
            <div className="profile-sub-text">
              Score in %{' '}
              <span
                className="info-button"
                onClick={() => {
                  showAlert('', 'An 80% score means your relations requests "edit" and "refusal" has made your score dropped by 20%.');
                }}>
                i
              </span>
            </div>
          </div>
        </div>

        <br />
        <br />
        <div className="jc-center">
          <button className="submit-btn margin-right-oneem margin-left-oneem" style={{ marginBottom: '1em'}} onClick={handleFeedback}>
            Feedback
          </button>
          <button
            className="submit-btn margin-right-oneem margin-left-oneem"
            style={{ marginBottom: '1em' }}
            onClick={handleMoreFeature}>
            More Features
          </button>
          <button className="submit-btn margin-right-oneem margin-left-oneem" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </PageContent>
      <Marquee style={{bottom: '1em', position: 'absolute'}}><a style={{color: 'white'}} href="https://compr.fr" target="_blank" rel="noreferrer">All Rights Reserved ©2002-2024 PROD LLC</a></Marquee>
    </div>
  );
};

export default MyProfile;
