import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../apis/ServerApis';

import { getErrorText } from '../../utility/helperFunctions';
import { handleException, handleFailure, showAlert } from '../../utility/util';
import { clearLocalStorage } from '../../utility/localStorage';
import {
  functionDepartment,
  colleagueRole,
  levelDesignation,
  peerLevel
} from '../../utility/constatnt';
import { useEffect } from 'react';
import { SmallHeader } from '../../components/common/SmallHeader';
import { FormPopupSelect } from '../../components/common/FormPopup/FormPopupSelect';
import { RoutePaths } from '../../navigation/RoutePath';

const EditColleague = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm();

  // colleague state for form
  const [colleague, setColleague] = useState(null);
  const [functionDept, setFunctionDept] = useState(functionDepartment);
  const [functions, setFunction] = useState('');
  const [roleDept, setRoleDept] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    console.log(colleague);
    console.log(functions);
    console.log(window.location.href);
    const editUrl = window.location.href;
    const splitUrl = editUrl.split('/');
    const url = `get-colleague-new/${splitUrl[splitUrl.length - 2]}/${
      splitUrl[splitUrl.length - 1]
    }`;
    console.log(url);
    api('GET', url)
      .then((response) => {
        if (response.success) {
          console.log(response);

          setTimeout(
            () =>
              setColleague({
                colleaguename: response.data.user.name,
                email: response.data.user.email,
                function: response.data.function,
                role: response.data.role,
                level: response.data.level,
                peerlevel: response.data.peer_level
              }),
            100
          );
          // Fill Role array according to selected Function
          handleFunctionDept(response.data.function);
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  }, []);

  // effect runs when colleague state is updated
  useEffect(() => {
    // reset form with colleague data
    reset(colleague);
  }, [colleague]);

  // Handle Function Department Changes
  const handleFunctionDept = (selectedDept) => {
    console.log(selectedDept);
    let arrRole1 = [];

    // Fill Role array according to selected Function
    arrRole1 = colleagueRole.filter((data) => data.value.includes(selectedDept));
    const sortRole = [...arrRole1].sort((a,b) => a.label > b.label ? 1 : -1);

    setFunction(selectedDept);
    setRoleDept(sortRole);
    setColleague((prevColleague) => ({ ...prevColleague, function: selectedDept }));
  };

  // Handle Edit Colleague
  const onSubmit = (data) => {
    console.log(data);
    const editColleagueParams = {
      function: functions,
      role: data.role,
      level: data.level,
      peer_level: data.peerlevel,
      tags: ''
    };

    const editUrl = window.location.href;
    const splitUrl = editUrl.split('/');
    const url = `update-colleague-data-new/${splitUrl[splitUrl.length - 2]}/${
      splitUrl[splitUrl.length - 1]
    }`;
    api('POST', url, editColleagueParams)
      .then((response) => {
        if (response.success) {
          console.log(response);
          showAlert('Success', 'Update successfully');
          clearLocalStorage();
          navigate(RoutePaths.auth);
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  return (
    <div className="container">
      <SmallHeader title="Profile" />
      <form onSubmit={handleSubmit(onSubmit)} className="text-center h-100">
        <div className="form-input-container">
          <div className="form-input-group">
            <input
              className="form-input form-input-with-icon form-input-username"
              placeholder="colleague name"
              readOnly
              {...register('colleaguename', { required: true })}
            />
            <div className="error-text">{getErrorText(errors, 'colleaguename')}</div>
          </div>

          <div className="form-input-group">
            <input
              className="form-input form-input-with-icon form-input-email"
              placeholder="email"
              readOnly
              {...register('email', { required: true })}
            />
            <div className="error-text">{getErrorText(errors, 'email')}</div>
          </div>

          <div className="form-pop-up-input-group flex">
            <Controller
              control={control}
              name="function"
              render={({ field: { value, ref } }) => (
                <FormPopupSelect
                  placeholder="function"
                  inputRef={ref}
                  value={functionDept.find((c) => c.value === value)}
                  onChange={(val) => handleFunctionDept(val.value)}
                  options={functionDept}
                />
              )}
            />
            <div className="error-text">{getErrorText(errors, 'function')}</div>
          </div>

          <div className="form-pop-up-input-group flex">
            <Controller
              control={control}
              name="role"
              render={({ field: { onChange, value, ref } }) => (
                <FormPopupSelect
                  placeholder="role"
                  inputRef={ref}
                  value={roleDept.find((c) => c.label === value) || ''}
                  onChange={(val) => onChange(val.label)}
                  options={roleDept}
                />
              )}
            />
            <div className="error-text">{getErrorText(errors, 'role')}</div>
          </div>

          <div className="form-pop-up-input-group flex">
            <Controller
              control={control}
              name="level"
              rules={{ required: true }}
              render={({ field: { onChange, value, ref } }) => (
                <FormPopupSelect
                  placeholder="level"
                  inputRef={ref}
                  value={levelDesignation.find((c) => c.value === value) || ''}
                  onChange={(val) => onChange(val.value)}
                  options={levelDesignation}
                />
              )}
            />
            <div className="error-text">{getErrorText(errors, 'level')}</div>
          </div>

          <div className="form-pop-up-input-group flex">
            <Controller
              control={control}
              name="peerlevel"
              rules={{ required: true }}
              render={({ field: { onChange, value, ref } }) => (
                <FormPopupSelect
                  placeholder="peer level"
                  inputRef={ref}
                  value={peerLevel.find((c) => c.value === value) || ''}
                  onChange={(val) => onChange(val.value)}
                  options={peerLevel}
                />
              )}
            />
            <div className="error-text">{getErrorText(errors, 'peerlevel')}</div>
          </div>
        </div>

        <button className="submit-btn" type="submit">
          Save
        </button>
      </form>
    </div>
  );
};

export default EditColleague;
