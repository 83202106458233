import swal from 'sweetalert';
import { clearLocalStorage } from './localStorage';

// Show/Hide spinner
export const showSpinner = (displaySpinner) => {
  if (document.getElementById('spinner')) {
    if (displaySpinner) {
      window.document.getElementById('spinner').style.display = '';
    } else {
      window.document.getElementById('spinner').style.display = 'none';
    }
  }
};

// For HandleResponseCode and it return true or false
export const handleResponseCode = (response) => {
  if (response) {
    return true;
  }
  return false;
};

// Show alert using sweetalert Plugin
export const showAlert = (title, message) => {
  swal(title, message);
};

// When api gets fail then used handle failure
export const handleFailure = (response) => {
  if (!response.success && response.message) {
    showAlert('Error', response.message);
  } else {
    showAlert('Error', 'Something went wrong. Please try again.');
  }
};

// export const handleLogout = () => {
//   const locationSetupVal = getLocalStorageItem(locationSetup);
//   const notificationSetupVal = getLocalStorageItem(notificationSetup);
//   const ageconfirmationSetupVal = getLocalStorageItem(ageconfirmationSetup);
//   const notificationTokenVal = getLocalStorageItem(notificationToken);
//   const countryval = getLocalStorageItem(country);
//   localStorage.clear();

//   setLocalStorageItem(locationSetup, locationSetupVal);
//   setLocalStorageItem(notificationSetup, notificationSetupVal);
//   setLocalStorageItem(ageconfirmationSetup, ageconfirmationSetupVal);
//   setLocalStorageItem(notificationToken, notificationTokenVal);
//   setLocalStorageItem(country, countryval);
// };

// Handle Exception like if api fall in to catch block so we call it to show error message
export const handleException = (error) => {
  if (error == 401 || error < 500) {
    showAlert('Error', 'Please login again and try.');
    window.location.href = '/#';
    clearLocalStorage();
  } else {
    showAlert('Error', 'Something went wrong. Please try again.');
  }
};

// Handle EmailId Format
export const checkEmailisValid = (email) => {
  const pattern = new RegExp(
    // eslint-disable-next-line comma-dangle
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
  );
  if (pattern.test(email)) {
    return true;
  }
  return false;
};

// Handle Password Format
export const checkPasswordisValid = (password) => {
  const pattern = new RegExp(
    // eslint-disable-next-line comma-dangle
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
  );
  if (pattern.test(password)) {
    return true;
  }
  return false;
};

export const generateInitialImage = (name) => {
  if (!name) {
    return;
  }
  const nameParts = name.split(" ");
  if (nameParts && nameParts.length && nameParts.length > 2) {
    name = nameParts[0] + ' ' + nameParts[1];
  }

  return `https://api.dicebear.com/5.x/initials/svg?padding=20px&backgroundColor=D9D9D9&seed=${name}&scale=60&textColor=000000`;
}
