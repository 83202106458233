export const MainTheme = {
  colors: {
    primary: '#76839d',
    secondary: '#262E48',
    tertiary: '#5A8DEE',
    gradientPrimary: '#fec9e2',
    gradientTertiary: '#b3f5f0',
    gray: 'gray',
    lightGray: '#D3D3D3',
    icon: '#FF77B6',
    seperator: '#F6F6F6',
    heading: '#5F4950',
    white: "#ffffff"
  }
};
