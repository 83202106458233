import React from 'react';
import { ThemeProvider } from 'styled-components';
import ReactGA from "react-ga4";

import './App.css';
import { MainTheme } from './styles/theme';
import MainNavigator from './navigation/MainNavigator';
import './fontawesome';

function App() {
  ReactGA.initialize("G-RSZCTN6W87");

  setTimeout(() => {
    if (window.document.getElementById('splashscreen')) {
       window.document.getElementById('splashscreen').style.display = 'none';
    }
  }, 2000);

  return (
    <ThemeProvider theme={MainTheme}>
      <MainNavigator />
      <div className="spinner-container" id="spinner" style={{ display: 'none' }}>
        <div className="spinner"></div>
        {/* <div className='spinner-text'>Loading...</div> */}
      </div>
    </ThemeProvider>
  );
}

export default App;
