/* eslint-disable react/display-name */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

export const CustomCheckbox = ({ label, value, onChange }) => {
  return (
    <div onClick={() => onChange(!value)} style={{ cursor: 'pointer' }}>
      <input
        style={{ display: 'none' }}
        type="checkbox"
        value={value}
        checked={value}
        onChange={(e) => {
          console.log(e.target);
          onChange(e.target.checked);
        }}
      />
      <div className="flex-row-left normal-text">
        <div>
          <CheckboxBorder className="flex-center">
            {value ? <FontAwesomeIcon icon="check" /> : ' '}
          </CheckboxBorder>
        </div>
        {typeof label === 'function' ? label() : label}
      </div>
    </div>
  );
};

const CheckboxBorder = styled.div`
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 4px;
  height: 20px;
  width: 20px;
  margin-right: 1rem;
`;
