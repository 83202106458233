export const RoutePaths = {
  auth: '/',
  signup: {
    name: 'signup',
    fullPath: '/signup'
  },
  resetPassword: '/resetpassword',
  addCompany: '/addcompany',
  addColleague: '/addcolleague',
  editColleague: '/get-colleague/:id/:token',
  confirmColleague: '/update-confirmation/:id/:token',
  myProfile: '/myprofile',
  publicProfile: '/profile/:token'
};
